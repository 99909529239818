.header a:hover {

    color: #FE6261;
}

.header a {
    transition: color 0.4s;
    padding: 20px;
    display: inline-block;
    font-size: 1.5rem;
    opacity: 1;
    animation: fadeIn 1.5s linear;
    color: black;
}

@media screen and (max-width: 800px),
(pointer:none),
(pointer:coarse) {

    .header {
        max-height: 100vh;
    }

    .header a {
        margin: auto;
        display: block;
        padding: 20px;
        font-size: 3rem;
        opacity: 1;
        animation: fadeIn 1.5s linear;
        color: black;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {

        opacity: 1;
    }
}

.header ul {
    margin: 0;
    text-align: center;
    display: block;
    margin-block-start: 0;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0;
}

.header {
    height: 100vh;
    margin: 0;
    color: white;
}