.card {
    background-color: white !important;
    display: inline-block;
    text-align: center;
    min-height: 30rem;
    width: 30rem;
}

.card p {
    font-size: 1.5rem;
}

.card button {
    margin-bottom: 10rem;
    font-size: 1.5rem;
    color: white;
    background-color: #FE6261;
}

.card h3 {
    font-size: 3rem;
    color: #FE6261;
}

.cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


@media (max-width: 900px) {
    .cardSection {
        flex-direction: column;
        align-items: center;
    }

    .card p {
        font-size: 2rem;
    }

}