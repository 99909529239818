@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&display=swap');


* {
  font-family: "League Spartan", sans-serif;
  font-optical-sizing: auto;
}




@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {

    opacity: 1;
  }
}

.title {
  text-align: center;
  height: 30%;
  margin: auto;
  font-size: 6rem;

}

.title h2 {
  font-size: 10rem;
  text-align: center;
}

.title p {
  font-size: 2rem;
}

a img:hover {
  scale: 110%;
  cursor: pointer;
}


bold {
  font-weight: bold;
}

h3 {
  font-size: 5rem;
  color: #FE6261;
}

.App {
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center;
  background-size: cover;


  /* Center the image */
  text-align: center;
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}




@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


button {
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;
  border: none;

}

button:hover {
  scale: 110%;
}

.gal {


  width: 50vw;
  margin: 2rem auto;
  display: block
}

@media screen and (max-width: 800px),
(pointer:none),
(pointer:coarse) {
  .title h2 {
    font-size: 5rem;
    text-align: center;
  }

  .gal {


    width: 80vw;
    margin: 2rem auto;
    display: block
  }

}

p {
  margin: 5%;
}
.hamburger-react {
  margin: auto;
}


.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}