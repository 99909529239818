.container {

    margin: 0 80px;

    position: relative;
    display: inline-block;
    color: white;
    border-radius: 20%;
    width: 30rem;
    height: 20rem;
    padding: 0px;
    background-color: #FE6261;
    overflow: hidden;
    opacity: 1;
    animation: fadeIn 0.8s linear;
}

.techIcons {
    position: absolute;
    left: 10%;
    z-index: 2;
}

.container img {
    height: 60%;
    width: 100%;
    margin: 0;
    border-bottom: 5px solid;
}

.projectTitle {
    margin: 0;
    padding: 0;
}



/* Extra small devices (phones, 600px and down) */
@media screen and (max-width: 800px),
(pointer:none),
(pointer:coarse) {
    .container {
        border: black 3px solid;

        display: block;
        animation: none;
        width: 95vw;
        aspect-ratio: 16/9;
        margin: 3rem 0;
        border-radius: 0 !important;
        position: relative;
        color: white;
        padding: 0px;
        background-color: #FE6261;
        overflow: hidden;
        opacity: 1;
    }

}



@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}