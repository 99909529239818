.hamburger {
    width: 0;
    height: 0;
    visibility: hidden;
}

.navbar {
    margin: 0;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0.5rem 0rem;
    background-color: #FE6261;
    box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
}

.navbar a {
    color: white;
    transition: color 0.4s;

    padding: 20px;
}

.navbar li {
    display: inline-block;
    list-style-type: none;
    margin: 0 1rem;
}

.navbar a:hover {

    color: black;
}

.navbar-links {

    margin-left: auto;
    margin: 20px;
}

a {
    text-decoration: none;
}

@media screen and (max-width: 800px),
(pointer:none),
(pointer:coarse) {

    .min {
        max-height: 50px !important;
    }

    .navbar {
        transition: max-height 1s;

        margin: 0;
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;
        padding: 0.5rem 0rem;
        background-color: #FE6261;
        box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
    }

    .hamburger {
        margin: auto;
        width: 48px;
        height: 48px;
        visibility: visible;
    }

    .list {
        padding: 0 !important;
    }

    .hide {

        visibility: hidden;
    }

    .navbar ul {
        text-align: center;
        margin: auto;
    }

    .navbar a {
        margin: auto;
        display: block;
        font-size: 2rem;

    }
}